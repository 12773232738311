<template>
  <section
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-3 mb-3">
        {{ $t('Purchase Premium Licences') }}
      </h1>

    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        md="12"
        lg="4"
        class="mx-auto"
      >
        <b-row>
          <b-col>
            <b-card
              align="center"
            >
              <h3>{{ $t('Free') }}</h3>
              <b-card-text>{{ $t('Get all the data you need') }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold">$</sup>
                  <span class="pricing-basic-value font-weight-bolder">0.00</span>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  {{ $t('Flash data entry') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('39K courses available') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('50+ Stats') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Multi-games Stats analysis') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Digital Scorecard') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Spider Coach') }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="12"
        lg="4"
        class="mx-auto"
      >
        <b-row>
          <b-col>
            <b-card
              class="popular-success"
              align="center"
            >

              <h3>{{ $t('Premium (Performance)') }}</h3>
              <b-card-text>{{ $t('Get all the data you need') }}</b-card-text>

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-success">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-success">{{ totalLight }}</span>
                  <span
                    v-if="discountLightFinal > 0 "
                    class="pricing-basic-value"
                    style="font-size:13px; font-weight: bold"
                  >
                    (- {{ discountLightFinal }} %)
                  </span>
                </div>
              </div>

              <p v-if="discountLightFinal > 0 ">
                <span
                  class="pricing-basic-value text-success"
                  style="font-size:13px;"
                >
                  € {{ priceLight }} {{ $t('per licence') }}
                </span>
              </p>

              <label for="demo-sb">{{ $t('Number of licences') }}</label>
              <b-form-spinbutton
                id="demo-sb"
                v-model="countLight"
                min="1"
                max="1000"
              />

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="success"
                @click="buyLicences(countLight, 'LITE')"
              >
                {{ $t('Purchase') }}
              </b-button>

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  {{ $t('Flash data entry') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('39K courses available') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('50+ Stats') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Multi-games Stats analysis') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Digital Scorecard') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Spider Coach') }}
                </b-list-group-item>
                <b-list-group-item class="text-success">
                  {{ $t('Spider Community') }}
                </b-list-group-item>
                <b-list-group-item class="text-success">
                  {{ $t('Spider Practice') }}
                </b-list-group-item>
              </b-list-group>

            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="12"
        lg="4"
        class="mx-auto"
      >
        <b-row>
          <b-col>
            <b-card
              class="popular"
              align="center"
            >
              <h3>{{ $t('Premium (Tour)') }}</h3>
              <b-card-text>{{ $t('Get all the data you need') }}</b-card-text>

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ totalStandard }}</span>
                  <span
                    v-if="discountStandardFinal > 0 "
                    class="pricing-basic-value"
                    style="font-size:13px; font-weight: bold"
                  >
                    (- {{ discountStandardFinal }} %)
                  </span>
                </div>
              </div>

              <p v-if="discountStandardFinal > 0 ">
                <span
                  class="pricing-basic-value text-primary"
                  style="font-size:13px;"
                >
                  € {{ priceStandard }} {{ $t('per licence') }}
                </span>
              </p>

              <label for="demo-sb">{{ $t('Number of licences') }}</label>
              <b-form-spinbutton
                id="demo-sb"
                v-model="countStandard"
                min="1"
                max="1000"
              />

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click="buyLicences(countStandard, 'STANDARD')"
              >
                {{ $t('Purchase') }}
              </b-button>

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  {{ $t('Flash data entry') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('39K courses available') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('50+ Stats') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Multi-games Stats analysis') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Digital Scorecard') }}
                </b-list-group-item>
                <b-list-group-item>
                  {{ $t('Spider Coach') }}
                </b-list-group-item>
                <b-list-group-item class="text-success">
                  {{ $t('Spider Community') }}
                </b-list-group-item>
                <b-list-group-item class="text-success">
                  {{ $t('Spider Practice') }}
                </b-list-group-item>
                <b-list-group-item class="text-primary">
                  {{ $t('400+ Stats') }}
                </b-list-group-item>
                <b-list-group-item class="text-primary">
                  {{ $t('Spider Coach +') }}
                </b-list-group-item>
                <b-list-group-item class="text-primary">
                  {{ $t('Digital Scorecard +') }}
                </b-list-group-item>
                <b-list-group-item class="text-primary">
                  {{ $t('Strokes Gained Benchmarks') }}
                </b-list-group-item>
                <b-list-group-item class="text-primary">
                  {{ $t('Spydey (Digital Caddy)') }}
                </b-list-group-item>
                <b-list-group-item class="text-primary">
                  {{ $t('Player Dashboards') }}
                </b-list-group-item>
              </b-list-group>

              <b-row>
                <b-col />
                <b-col />
                <b-col />
              </b-row>

            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BCardText, BListGroup, BListGroupItem, BButton, BFormSpinbutton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stripe: null,
      countLight: 1,
      countStandard: 1,
      discountStandard: [
        [0, 0],
        [2, 8],
        [6, 12],
        [10, 22],
        [15, 24],
        [20, 27],
        [25, 30],
        [30, 32],
        [40, 36],
        [50, 39],
        [100, 46],
        [200, 52],
      ],
      discountLight: [
        [0, 0],
        [2, 8],
        [6, 10],
        [10, 13],
        [15, 16],
        [20, 18],
        [25, 20],
        [30, 22],
        [40, 25],
        [50, 28],
        [100, 35],
        [200, 45],
      ],
    }
  },
  computed: {
    discountStandardFinal() {
      const d = this.discountStandard.filter(item => item[0] <= this.countStandard).pop()
      return d[1]
    },
    priceStandard() {
      return (89.99 * (1 - this.discountStandardFinal / 100)).toFixed(2)
    },
    totalStandard() {
      return (this.priceStandard * this.countStandard).toFixed(2)
    },
    discountLightFinal() {
      const d = this.discountLight.filter(item => item[0] <= this.countLight).pop()
      return d[1]
    },
    priceLight() {
      return (39.99 * (1 - this.discountLightFinal / 100)).toFixed(2)
    },
    totalLight() {
      return (this.priceLight * this.countLight).toFixed(2)
    },
  },
  created() {
    /* eslint-disable */
    this.stripe = Stripe("pk_live_uj39bIxDKequNB3q849L0ft4")
  },
  methods: {
    buyLicences(count, license_type) {
      axios.post('/api/subscription/stripe/', {
        count,
        license_type
      }).then((response) => {
        return this.stripe.redirectToCheckout({ sessionId: response.data.id })
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Request failed'),
            icon: 'SlashIcon',
            variant: 'danger',
            text: this.$t("We couldn't process your request for licences. Please contact us at support@spidergolf.fr"),
          },
        })
      })
      
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
.popular-success{
  border: 1px solid #25cfa9;
}
</style>
